
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
  props: {
    value: {
      type: Array as () => string[],
      default: () => [],
    },
  },
  data() {
    return {
      datePickerIsOpen: false,
      selectedRange: "",
      dateRanges: ["This Week", "Last Week", "Last Month", "This Month", "Last Year", "This Year"],
      dateRange: this.value,
    };
  },
  methods: {
    selectToday() {
      this.dateRange = [moment().format("YYYY-MM-DD")];
    },
  },
  computed: {
    dateRangeText(): string {
      if (this.dateRange[0] && this.dateRange[1]) {
        return `${this.dateRange[0]} - ${this.dateRange[1]}`;
      }
      if (this.dateRange[0]) {
        return `${this.dateRange[0]} and Above`;
      }
      return "";
    },
  },
  watch: {
    selectedRange(newVal) {
      let startDate = moment();
      let endDate = moment();
      switch (newVal) {
        case "This Week":
          startDate = moment().startOf("week");
          endDate = moment().endOf("week");
          break;
        case "Last Week":
          startDate = moment().subtract(1, "weeks").startOf("week");
          endDate = moment().subtract(1, "weeks").endOf("week");
          break;
        case "Last Month":
          startDate = moment().subtract(1, "months").startOf("month");
          endDate = moment().subtract(1, "months").endOf("month");
          break;
        case "This Month":
          startDate = moment().startOf("month");
          endDate = moment().endOf("month");
          break;
        case "Last Year":
          startDate = moment().subtract(1, "years").startOf("year");
          endDate = moment().subtract(1, "years").endOf("year");
          break;
        case "This Year":
          startDate = moment().startOf("year");
          endDate = moment().endOf("year");
          break;
      }
      this.dateRange = [startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD")];
    },
    dateRange(value: string[]) {
      const v: string[] = [];
      if (value[0]) {
        v.push(value[0] + " " + "00:00:00");
      }
      if (value[1]) {
        v.push(value[1] + " " + "23:59:59");
      }
      this.$emit("input", v);
    },
  },
});

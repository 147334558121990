import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Contact } from "../models/Contact";

export default class ContactService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getContacts(companyId: string) {
    if (!companyId) {
      return null;
    }
    return await this.db.collection("Contacts").where("companyId", "==", companyId).get();
  }

  public async getContactsDoc(contactIds: string[]) {
    if (!contactIds.length) {
      return null;
    }
    return await this.db.collection("Contacts").where("id", "in", contactIds).get();
  }

  public async removeContact(contact: Contact) {
    return await this.db.collection("Contacts").doc(contact.id).delete();
  }

  public async checkExist(contact: Contact) {
    return await this.db.collection("Contacts").where("companyId", "==", contact.companyId).where("email", "==", contact.email).get();
  }

  public async checkEmailExist(contact: Contact) {
    return await this.db
      .collection("Contacts")
      .where("companyId", "==", contact.companyId)
      .where("email", "==", contact.email)
      .where("id", "!=", contact.id)
      .get();
  }

  public async getByEmail(email: string, companyId: string) {
    return await this.db.collection("Contacts").where("companyId", "==", companyId).where("email", "==", email).get();
  }

  public async addContact(contact: Contact) {
    return await this.db.collection("Contacts").add(contact);
  }

  public async updateContactInfo(contact: Contact) {
    return await this.db.collection("Contacts").doc(contact.id).set(contact, { merge: true });
  }
}

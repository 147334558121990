
import Vue from "vue";
import sessionService from "@/services/sessionService";
import moment from "moment";
import formService from "@/services/formService";
import HeatMapVisitData from "@/components/audience/HeatMapVisitData.vue";
import { Form, Session, SessionVisit } from "../../../../shared/models";
import firebase from "../../../../shared/plugins/firebase-app";

export default Vue.extend({
  components: {
    HeatMapVisitData,
  },
  props: {
    form: {
      type: Object as () => Form,
      required: false,
    },
    dateRange: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedSession: null as Session | null,
      selectedSessions: [] as string[],
      selectedVisits: [] as string[],
      initState: true,
      chartData: null as any,
      unsubscribeList: [] as Array<() => void>,
      loading: false,
      infoMessage: "",
    };
  },
  mounted() {
    this.drawHierarchy();
  },
  methods: {
    async drawHierarchy() {
      try {
        if (!this.form) {
          this.infoMessage = "A form should be selected at least.";
          return;
        } else {
          this.infoMessage = "";
        }
        this.loading = true;
        this.initState = true;
        this.selectedSession = null;
        this.selectedSessions = [];
        this.selectedVisits = [];
        this.unsubscribeAllForms();
        const form = await formService.getDocById(this.form.id);
        const data = (await form.get()).data() as Form;
        if (data) {
          data.visits = (data.visits || []).sort((a, b) => (a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0));
          this.subscribeForm(form);
          data.children = [];
          this.chartData = data;
          const promises: Promise<any>[] = [];
          await this.loadFormChildren(this.chartData, this.chartData.id, "", promises);
          // Wait to load all children
          let hasAnyPendingPromise;
          do {
            await Promise.all(promises);
            hasAnyPendingPromise = await this.hasAnyPendingPromise(promises);
          } while (hasAnyPendingPromise);
        }
        this.initState = false;
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async loadSession(session: Session) {
      try {
        this.loading = true;
        this.selectedSession = session;
        this.filterFormSession(this.chartData, session, true);
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    filterFormSession(form: any, session: Session, alsoChilds = true) {
      if (session) {
        this.$set(
          form,
          "sessions",
          (form.sessions || []).filter((x) => x.id === session.id)
        );
        this.$set(
          form,
          "visits",
          (form.visits || []).filter((x) => x.sessionId === session.id)
        );
      } else {
        this.$set(form, "sessions", [...form._sessions]);
        this.$set(form, "visits", [...form._visits]);
      }
      if (alsoChilds) {
        for (let i = 0; i < form.children.length; i++) {
          this.filterFormSession(form.children[i], session, true);
        }
      }
    },
    async loadFormSessions(form: Form) {
      if (form) {
        const sessions: Array<{ id: string; datetime: any; clickerEmailAddress: string | null }> = [];
        const sessionDocs = await sessionService.getFormSessions(this.companyId, form.id, this.dateRange);
        sessionDocs.forEach((sessionDoc) => {
          var sessionData = sessionDoc.data() as Session;
          sessionData.id = sessionDoc.id;
          sessions.push({
            id: sessionDoc.id,
            datetime: sessionData.lastModifiedDateTime ? moment(sessionData.lastModifiedDateTime.toDate()).format("LLLL") : "",
            clickerEmailAddress: sessionData.clickerEmailAddress,
          });
        });
        this.$set(form, "sessions", sessions);
        (form as any)._sessions = [...sessions];
        form.visits = form.visits || [];
        if (this.dateRange[0]) {
          form.visits = form.visits.filter((x) => x.dateTime >= firebase.firestore.Timestamp.fromDate(new Date(this.dateRange[0])));
        }
        if (this.dateRange[1]) {
          form.visits = form.visits.filter((x) => x.dateTime <= firebase.firestore.Timestamp.fromDate(new Date(this.dateRange[1])));
        }
        (form as any)._visits = [...form.visits];
        this.filterFormSession(form, this.selectedSession as Session, false);
      }
    },
    isVisitLink(visitKey: string) {
      const x = visitKey.split("/");
      if (x.length) {
        return x[x.length - 1].startsWith("link:");
      }
      return false;
    },
    async loadFormChildren(form: Form, formIds: string, sessionId: string, promises: Promise<any>[] = []) {
      try {
        this.$set(form, "loading", true);
        await this.loadFormSessions(form);
        const children = (await sessionService.getSessionVisitChildrenDocs(
          this.companyId,
          sessionId,
          formIds
        )) as firebase.firestore.Query<firebase.firestore.DocumentData>;
        const nodes: any[] = [];
        this.subscribeFormQuery(children);
        for (const doc of (await children.get()).docs) {
          const sessionVisitData = doc.data() as SessionVisit;
          const formData = (await formService.getById(sessionVisitData.formId)).data() as Form;
          formData.visits = (formData.visits || []).sort((a, b) => (a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0));
          formData.children = [];
          formData.isLink = this.isVisitLink(sessionVisitData.visitKey);
          (formData as any).sessionId = sessionVisitData.sessionId;
          await this.loadFormSessions(formData);
          nodes.push(formData);
        }
        this.$set(form, "children", nodes);
      } finally {
        this.$set(form, "loading", false);
      }
      if (form.children) {
        for (let i = 0; i < form.children.length; i++) {
          // if (!(form.children[i] as any).isLink) {
          promises.push(
            this.loadFormChildren(form.children[i], `${formIds}/${form.children[i].id}`, (form.children[i] as any).sessionId, promises)
          );
          // }
        }
      }
    },
    promiseState(p) {
      const t = 1;
      return Promise.race([p, t]).then(
        (v) => (v === t ? "pending" : "fulfilled"),
        () => "rejected"
      );
    },
    async hasAnyPendingPromise(promises) {
      for (const p of promises) {
        if ((await this.promiseState(p)) == "pending") {
          return true;
        }
      }
      return false;
    },
    unsubscribeAllForms() {
      if (this.unsubscribeList) {
        for (const f of this.unsubscribeList) {
          f();
        }
      }
      this.unsubscribeList = [];
    },
    subscribeForm(form) {
      const x = form.onSnapshot((doc) => {
        if (!this.initState) {
          this.reloadForm(doc.data());
        }
      });
      this.unsubscribeList.push(x);
    },
    subscribeFormQuery(formQuery: firebase.firestore.Query<firebase.firestore.DocumentData>) {
      const x = formQuery.onSnapshot((snapshot) => {
        if (!this.initState) {
          snapshot.docs.forEach((doc) => {
            this.reloadForm(doc.data());
          });
        }
      });
      this.unsubscribeList.push(x);
    },
    async reloadForm(formData) {
      const form = this.findForm(this.chartData, formData.id);
      if (form) {
        this.$set(form, "name", formData.name);
        this.$set(form, "visits", formData.visits);
        this.$set(form, "counter", ((form as any).counter || 0) + 1);
        await this.loadFormSessions(form);
      }
    },
    findForm(rootForm: Form, id: string) {
      let form: Form;
      if (rootForm.id == id) {
        form = rootForm;
      } else {
        form = findForm(id, rootForm.children);
      }
      return form;
      function findForm(formId, children) {
        for (const f of children) {
          if (f.id === formId) {
            return f;
          } else {
            return findForm(formId, f.children);
          }
        }
        return null;
      }
    },
  },
  watch: {
    loading(value) {
      this.$emit("loading", value);
    },
  },
  beforeDestroy() {
    this.unsubscribeAllForms();
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
  },
});

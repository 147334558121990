import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { ContactGroup } from "../models";

export default class ContactGroupService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getContactGroup(contactGroupId: string) {
    if (!contactGroupId) {
      return null;
    }
    return await this.db.collection("ContactGroups").doc(contactGroupId).get();
  }

  public async getContactGroups(companyId: string) {
    if (!companyId) {
      return null;
    }
    return await this.db.collection("ContactGroups").where("companyId", "==", companyId).get();
  }

  public async getContactGroupsDoc(contactGroupIds: string[], companyId: string) {
    if (!contactGroupIds.length) {
      return null;
    }
    return await this.db.collection("ContactGroups").where("companyId", "==", companyId).where("id", "in", contactGroupIds).get();
  }

  public async removeContactGroup(contactGroup: ContactGroup) {
    return await this.db.collection("ContactGroups").doc(contactGroup.id).delete();
  }

  public async addContactGroup(contactGroup: ContactGroup) {
    return await this.db.collection("ContactGroups").add(contactGroup);
  }

  public async checkExist(contactGroup: ContactGroup) {
    return await this.db
      .collection("ContactGroups")
      .where("companyId", "==", contactGroup.companyId)
      .where("email", "==", contactGroup.email)
      .get();
  }

  public async checkEmailExist(contactGroup: ContactGroup) {
    return await this.db
      .collection("ContactGroups")
      .where("companyId", "==", contactGroup.companyId)
      .where("email", "==", contactGroup.email)
      .where("id", "!=", contactGroup.id)
      .get();
  }

  public async updateContactGroupInfo(contactGroup: ContactGroup) {
    return await this.db.collection("ContactGroups").doc(contactGroup.id).set(contactGroup, { merge: true });
  }

  public async addContactToGroup(groupid: string, contactid: string) {
    await this.db
      .collection("ContactGroups")
      .doc(groupid)
      .update({
        contacts: firebase.firestore.FieldValue.arrayUnion(contactid),
      });
  }

  public async removeContactFromGroups(companyId: string, contactId: string) {
    const groupDocs = await this.db
      .collection("ContactGroups")
      .where("companyId", "==", companyId)
      .where("contacts", "array-contains", contactId)
      .get();
    groupDocs.forEach((x) => {
      this.db
        .collection("ContactGroups")
        .doc(x.id)
        .update({
          contacts: firebase.firestore.FieldValue.arrayRemove(contactId),
        });
    });
  }

  public async getContactGroupsOfContact(companyId: string, contactId: string) {
    const groupDocs = await this.db
      .collection("ContactGroups")
      .where("companyId", "==", companyId)
      .where("contacts", "array-contains", contactId)
      .get();
    return groupDocs.docs.map((x) => x.id);
  }
}

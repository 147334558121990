import axios from "@/plugins/axios";

class IpsService {
  public async getIpDetail(ip: string) {
    const response = await axios.get("/GetIpDetails", { params: { ip: ip } });
    return response.data;
  }
}

const ipsService = new IpsService();
export default ipsService;


import Vue from "vue";
import sessionService from "@/services/sessionService";
import { SessionVisit } from "../../../../shared/models";

export default Vue.extend({
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    visitKey: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      loading: false,
      sessionVisit: null as SessionVisit | null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.sessionVisit =
          ((await sessionService.getSessionVisitByFormIds(this.companyId, this.sessionId, this.formIds))?.data() as SessionVisit) || null;
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    formIds(): string[] {
      return (this.visitKey || "").replace(/link:/gi, "").split("/");
    },
    companyId(): string {
      return this.$store.getters.companyId;
    },
    userId(): string {
      return this.$store.getters.userId;
    },
  },
});

import firebase, { db } from "../../../shared/plugins/firebase-app";
import axios from "@/plugins/axios";
import qs from "qs";
import $ from "jquery";
import { Form } from "../../../shared/models";
import BaseFormService from "../../../shared/services/FormService";
// import RenderService from "../../../backend-azure/services/RenderService";
// import { RenderData } from "../../../backend-azure/models/RenderData";

class FormService extends BaseFormService {
  constructor() {
    super(db);
  }

  public async saveForm(formData: Form) {
    await db.runTransaction(async (transaction) => {
      await this.saveFormInTransaction(transaction, formData);
    });
  }

  public async saveFormInTransaction(transaction: firebase.firestore.Transaction, formData: Form) {
    const form = { ...formData };

    delete form.children;
    form.links = (form.links || []).filter((x) => !!x);
    form.linkIds = form.links.map((x) => x.id);
    form.elements = (form.elements || []).filter((x) => !!x);
    if (!form.question.startsWith("<")) {
      form.question = `<p>${form.question}</p>`;
    }
    const formDoc = db.doc("/Forms/" + form.id);
    const formUsedElements = db.doc("/UsedElements/" + form.id);
    if (form.archived) {
      const usageOfForm = await db
        .collection("UsedElements")
        .where("companyId", "==", form.companyId)
        .where("formIds", "array-contains", form.id)
        .get();
      if (usageOfForm.size > 0) {
        formData.archived = false;
        throw new Error(`You can not archive this form. because the form is used in ${usageOfForm.size} another form.`);
      }
    }
    const formWithoutError = { ...form };
    delete formWithoutError["errors"];

    transaction.set(formDoc, formWithoutError, { merge: true });
    await this.setUsedElements(transaction, form.companyId, form.id, form.question, formUsedElements);
  }

  public async setUsedElements(
    transaction: firebase.firestore.Transaction,
    companyId: string,
    formId: string,
    content: string,
    formUsedElements: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ) {
    const elementIds: string[] = [];
    const formIds: string[] = [];
    const span = $(content || "").find("span[data-type='mention']");
    if (span && span.length) {
      for (let i = 0; i < span.length; i++) {
        const currentSpan = span.eq(i);
        const mentionId: string | undefined = currentSpan.attr("data-id");
        if (mentionId) {
          const x = mentionId.split("/");
          if (x.length == 2) {
            const formId = x[0];
            const elementId = x[1];
            formIds.push(formId);
            elementIds.push(elementId);
          } else {
            throw new Error(`Wrong used element mention, form Id:${formId}`);
          }
        }
      }
    }
    if (elementIds.length && formIds.length) {
      transaction.set(formUsedElements, {
        companyId,
        elementIds,
        formIds,
      });
    } else {
      transaction.delete(formUsedElements);
    }
  }

  public async changeFormParentKey(form: Form, newKey: string, companyId: string) {
    await db.runTransaction(async (transaction) => {
      await this.changeFormParentKeyInTransaction(transaction, form, newKey, companyId);
    });
  }

  public async changeFormParentKeyInTransaction(
    transaction: firebase.firestore.Transaction,
    form: Form,
    newKey: string,
    companyId: string
  ) {
    const oldKey = form.key;
    form.parentKey = newKey;
    form.key = newKey ? newKey + "/" + form.id : form.id;
    await this.saveFormInTransaction(transaction, form);

    const children = (await this.getChildrenByCompanyId(oldKey, companyId)).docs;
    for (const child of children) {
      const childForm = child.data() as Form;
      await this.changeFormParentKeyInTransaction(transaction, childForm, form.key, companyId);
    }
  }

  public async renderForMail(formKey: string, linkId: string) {
    const response = await axios.post(`/Render`, { fullPath: formKey, linkId, renderMode: "Mail" });
    return response.data;
  }

  public async renderForWeb(sessionId: string, linkId: string, visitKey: string, fullPath: string) {
    const response = await axios.post("/Render", { sessionId, linkId: linkId || "", visitKey, fullPath, renderMode: "Web" });
    return response.data;
  }

  public async renderForPreview(formKey: string, linkId: string | null, theme, layoutSettings) {
    const response = await axios.post(`/Render`, { fullPath: formKey, linkId, theme, layoutSettings, renderMode: "Preview" });
    return response.data;
    // const requestData = { fullPath: formKey, linkId, theme, layoutSettings, renderMode: "Preview" };
    // const validateResult = await RenderService.validateRequest(requestData, requestData.renderMode);
    // if (!validateResult) {
    //   throw new Error("Invalid request");
    // }
    // const clientIp = "localhost";
    // const renderData: RenderData = {
    //   fullPath: requestData.fullPath,
    //   visitKey: undefined,
    //   linkId: requestData.linkId,
    //   sessionId: undefined,
    //   theme: requestData.theme,
    //   layoutSettings: requestData.layoutSettings,
    //   renderMode: "Preview",
    //   clientIp: clientIp,
    // };
    // const result = await RenderService.render(renderData);
    // return result.data;
  }

  public async sendFormViaMail(mailDetail: any, formKey: string, linkId: string) {
    const response = await axios.post(`/SendFormLinkViaEmail`, {
      params: { mailDetail, fullPath: formKey, linkId },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  public async getFullFormDetails(companyId: string, withPC: boolean, userId: string) {
    const response = await axios.get("/GetFullFormDetails/", { params: { companyId: companyId, withPC: withPC, userId: userId } });
    return response.data;
  }

  public async getFormLayouts() {
    const response = await axios.get("/GetFormLayouts");
    return response.data;
  }

  public async getLinkableElements(companyId: string, formId: string) {
    const response = await axios.get("/GetLinkableElements", { params: { companyId: companyId, formId: formId } });
    return response.data;
  }

  public async updateFolderId(formId: string, folderId: string) {
    const response = await axios.put("/Form", { formId: formId, folderId: folderId });
    return response.data;
  }
}

const formService = new FormService();
export default formService;

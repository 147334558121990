import IdGenerator from "../extensions/IdGenerator";
import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Session, SessionVisit, SessionValue } from "../models";
import { FindSessionsCriteriaRequestModel } from "../models/requests/FindSessionsCriteriaRequestModel";
import { ConcurrentSessionDetails } from "../models/Dto/ConcurrentSessionDetails";

export default class SessionService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async saveSession(session: Session) {
    if (!session.id) {
      session.id = IdGenerator.newId();
    }
    const doc = await this.db.collection("Sessions").doc(session.id);
    await doc.set(session);
    return doc;
  }

  public async saveSessionVisit(sessionVisit: SessionVisit) {
    await this.db.collection("SessionVisits").doc(sessionVisit.id).set(sessionVisit);
  }

  public async getAll(companyId: string) {
    return await this.db.collection("Sessions").where("companyId", "==", companyId).get();
  }

  public async getSessionVisitBySessionId(companyId: string, sessionId: string) {
    return await this.db.collection("SessionVisits").where("companyId", "==", companyId).where("mainSessionId", "==", sessionId).get();
  }

  private async getSessionVisitCount(id: string) {
    const sessionVisits = await this.db.collection("SessionVisits").where("mainSessionId", "==", id).get();
    return sessionVisits.size;
  }

  private async getConcurrentSessionDetails(ids: string[]) {
    const concurrentItems: ConcurrentSessionDetails[] = [];
    for (const id of ids) {
      const doc = await this.db.collection("Sessions").where("id", "==", id).get();
      if (doc.docs && doc.docs.length > 0) {
        const sessionData = doc.docs[0].data() as Session;
        concurrentItems.push({
          id: sessionData.id,
          sessionLastUpdate: sessionData.lastModifiedDateTime,
          sessionStartTime: sessionData.startDatetime,
        } as ConcurrentSessionDetails);
      }
    }
    await concurrentItems.sort((a, b) => {
      return a.sessionStartTime.seconds - b.sessionStartTime.seconds;
    });
    return concurrentItems;
  }

  public async getByCriteria(criteria: FindSessionsCriteriaRequestModel): Promise<Session[]> {
    let collection = this.db.collection("Sessions").where("companyId", "==", criteria.companyId);
    if (criteria.formId) {
      collection = collection.where("formId", "==", criteria.formId);
    }
    if (criteria.dateRange[0]) {
      collection = collection.where("lastModifiedDateTime", ">=", firebase.firestore.Timestamp.fromDate(new Date(criteria.dateRange[0])));
    }
    if (criteria.dateRange[1]) {
      collection = collection.where("lastModifiedDateTime", "<=", firebase.firestore.Timestamp.fromDate(new Date(criteria.dateRange[1])));
    }
    collection = collection.orderBy("lastModifiedDateTime", "desc");
    const result = await collection.get();
    const sessions = result.docs.map(
      (x) =>
        ({
          ...x.data(),
          id: x.id,
        } as Session)
    );

    for (const session of sessions) {
      session.depth = await this.getSessionVisitCount(session.id);
      if (session.concurrentSessionIds) {
        if (!session.concurrentSessionDetails) session.concurrentSessionDetails = [];
        session.concurrentSessionDetails = await this.getConcurrentSessionDetails(session.concurrentSessionIds);
      }
    }

    if (criteria.seenFilter && criteria.userId) {
      switch (criteria.seenFilter) {
        case "Read":
          return sessions.filter(
            (item) =>
              item.lastSeenDateTime &&
              item.lastSeenDateTime.seconds > item.lastModifiedDateTime.seconds &&
              (!item.seenUsers || (item.seenUsers && item.seenUsers.includes(criteria.userId as string)))
          );
        case "Unread":
          return sessions.filter(
            (item) =>
              !item.lastSeenDateTime ||
              item.lastSeenDateTime.seconds < item.lastModifiedDateTime.seconds ||
              (item.seenUsers && !item.seenUsers.includes(criteria.userId as string))
          );
        default:
          return sessions;
      }
    }
    return sessions;
  }

  public async getByIds(ids: Array<string>): Promise<Session[]> {
    const sessionIdSubList: Array<Array<string>> = [];
    const sessions: Array<Session> = [];
    for (let i = 0; i < ids.length; i += 10) {
      sessionIdSubList.push(ids.slice(i, i + 10 > ids.length ? ids.length - i : 10));
    }
    for (let item = 0; item < sessionIdSubList.length; item++) {
      const dbSessions = (await this.db.collection("Sessions").where("id", "in", sessionIdSubList[item]).get()).docs;
      dbSessions.forEach((item) => sessions.push(item.data()));
    }
    return sessions;
  }

  public async getById(id: string) {
    return await this.db.collection("Sessions").doc(id).get();
  }

  public async updateSession(session: Partial<Session>, id: string) {
    return await this.db.collection("Sessions").doc(id).set(session, { merge: true });
  }

  public async getSessionVisitByFormIdsString(companyId: string, sessionId: string, formIds: string) {
    const x = await this.db
      .collection("SessionVisits")
      .where("companyId", "==", companyId)
      .where("mainSessionId", "==", sessionId)
      .where("formIds", "==", formIds)
      .get();
    if (x.size > 1) {
      throw new Error(`Duplicate session value with Form-Ids:${formIds}`);
    }
    if (x.size == 0) {
      return null;
    }
    return x.docs[0];
  }

  public async getAdminSessionVisitByFormIdsString(sessionId: string, formIds: string) {
    const x = await this.db.collection("SessionVisits").where("mainSessionId", "==", sessionId).where("formIds", "==", formIds).get();
    if (x.size > 1) {
      throw new Error(`Duplicate session value with Form-Ids:${formIds}`);
    }
    if (x.size == 0) {
      return null;
    }
    return x.docs[0];
  }

  public async getSessionVisitByFormIds(companyId: string, sessionId: string, formIds: string[]) {
    return this.getSessionVisitByFormIdsString(companyId, sessionId, formIds.join("/"));
  }

  public async getSessionVisitValue(companyId: string, sessionId: string, elementId: string) {
    const x = await this.db.collection("SessionVisits").where("companyId", "==", companyId).where("mainSessionId", "==", sessionId).get();
    for (const doc of x.docs) {
      const docData = doc.data() as SessionVisit;
      for (const sessionValue of docData.values) {
        if (sessionValue.elementId === elementId) {
          return sessionValue.value as string;
        }
      }
    }
    return null;
  }

  public async getAdminSessionVisitByFormIds(sessionId: string, formIds: string[]) {
    return this.getAdminSessionVisitByFormIdsString(sessionId, formIds.join("/"));
  }

  public async getSessionVisitChildren(companyId: string, sessionId: string, formIds: string[]) {
    return await this.getSessionVisitChildren2(companyId, sessionId, formIds.join("/"));
  }

  public async getSessionVisitChildren2(companyId: string, sessionId: string, formIds: string) {
    return await this.db
      .collection("SessionVisits")
      .where("companyId", "==", companyId)
      .where("mainSessionId", "==", sessionId)
      .where("previousFormIds", "==", formIds)
      .get();
  }

  public getSessionVisitChildrenDocs(companyId: string, sessionId: string, formIds: string) {
    let x = this.db.collection("SessionVisits").where("companyId", "==", companyId);
    if (sessionId) {
      x = x.where("mainSessionId", "==", sessionId);
    }
    x = x.where("previousFormIds", "==", formIds);
    return x;
  }

  public async addSessionVisit(sessionVisit: SessionVisit) {
    if (sessionVisit.id) {
      return await this.addSessionVisitWithCustomId(this.db.collection("SessionVisits"), sessionVisit, sessionVisit.id);
    }
    const doc = await this.db.collection("SessionVisits").add(sessionVisit);
    return doc.id;
  }

  public async updateSessionVisit(sessionVisit: SessionVisit) {
    await this.db.collection("SessionVisits").doc(sessionVisit.id).set(sessionVisit, { merge: true });
  }

  public async updateSessionVisitValues(id: string, values: SessionValue[]) {
    await this.db.collection("SessionVisits").doc(id).update("values", values);
  }

  public async addSessionVisitWithCustomId(
    collection:
      | firebaseAdmin.firestore.CollectionReference<firebaseAdmin.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
    sessionVisit: SessionVisit,
    id: string
  ) {
    await collection.doc(id).set(sessionVisit);
    return id;
  }

  public async getFormSessions(companyId: string, formId: string | null, dateRange: string[]) {
    let x = this.db.collection("Sessions").where("companyId", "==", companyId);
    if (formId) {
      x = x.where("formId", "==", formId);
    }
    if (dateRange[0]) {
      x = x.where("lastModifiedDateTime", ">=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[0])));
    }
    if (dateRange[1]) {
      x = x.where("lastModifiedDateTime", "<=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[1])));
    }
    x = x.orderBy("lastModifiedDateTime", "desc");
    return await x.get();
  }

  public async getCommunicationScheduleSessions(companyId: string, communicationScheduleId: string) {
    return await this.db
      .collection("Sessions")
      .where("companyId", "==", companyId)
      .where("communicationScheduleId", "==", communicationScheduleId)
      .get();
  }
}

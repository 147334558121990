import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Form, FormVisit, LayoutSetting, LinkableElement, Session } from "../models";
export default class FormService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async rootFormsList(companyId: string) {
    return await this.db.collection("Forms").where("companyId", "==", companyId).where("parentKey", "==", "").orderBy("name").get();
  }

  public async childFormsList(companyId: string) {
    return await this.db
      .collection("Forms")
      .orderBy("parentKey")
      .where("companyId", "==", companyId)
      .where("parentKey", "!=", "")
      .orderBy("name")
      .get();
  }

  public async getFormPublishesCount(companyId: string, formId: string): Promise<number> {
    const x = await this.db.collection("Publishes").where("companyId", "==", companyId).where("formId", "==", formId).get();
    return x.size;
  }

  public async getById(id: string) {
    return await this.db.collection("Forms").doc(id).get();
  }

  public async UpdateFolderId(formId: string, folderId: string) {
    return await this.db.collection("Forms").doc(formId).update({ folderId: folderId });
  }

  public async getByKey(key: string) {
    const x = key.split("/");
    const id = x[x.length - 1];
    return this.getById(id);
  }

  public getDocById(id: string) {
    return this.db.collection("Forms").doc(id);
  }

  public async updateForm(form: Form) {
    return await this.db.doc(`/Forms/${form.id}`).set(form, { merge: true });
  }

  public async updateVisits(formId: string, visits: FormVisit[]) {
    return await this.db.doc(`/Forms/${formId}`).update("visits", visits);
  }

  public async getChildren(key: string) {
    return await this.db.collection("Forms").where("parentKey", "==", key).orderBy("order").get();
  }

  public async getChildrenByCompanyId(key: string, companyId: string) {
    return await this.db.collection("Forms").where("parentKey", "==", key).where("companyId", "==", companyId).orderBy("order").get();
  }

  public async getChildrenCountByCompanyId(key: string, companyId: string) {
    return (await this.db.collection("Forms").where("parentKey", "==", key).where("companyId", "==", companyId).get()).size;
  }

  public async getFormChildrenAndLinks(form: Form, companyId: string) {
    const childrenData = await this.getChildrenByCompanyId(form.key, companyId);
    const children = [] as any[];
    childrenData.forEach((doc) => {
      const f = doc.data() as Form;
      if (f.companyId && f.companyId == companyId) {
        f.id = doc.id;
        f.key = form.key + "/" + doc.id;
        f.children = [];
        f.publishesCount = f.publishesCount || 0;
        children.push(
          this.fixForm(JSON.parse(JSON.stringify({ ...f, isLink: false, visitKey: ((form as any).visitKey || form.key) + "/" + doc.id })))
        );
      }
    });
    for (const link of (form.links || []).filter((x) => x)) {
      const linkForm = (await this.getById(link.id)).data() as Form;
      if (linkForm) {
        children.push({
          id: linkForm.id,
          key: linkForm.key,
          name: linkForm.name,
          isLink: true,
          publishesCount: linkForm.publishesCount || 0,
          // children: [],
          visitKey: ((form as any).visitKey || form.key) + "/" + linkForm.key,
        });
      }
    }
    return children;
  }

  public async getUnseenSessionsCount(form: Form, companyId: string, userId: string) {
    const query = await this.db.collection("Sessions").where("companyId", "==", companyId).where("formId", "==", form.id).get();
    let count = 0;
    for (const doc of query.docs) {
      const data = doc.data() as Session;
      if (
        !data.lastSeenDateTime ||
        data.lastSeenDateTime.seconds < data.lastModifiedDateTime.seconds ||
        (data.seenUsers && !data.seenUsers.includes(userId))
      ) {
        count++;
      }
    }
    return count;
  }

  public getChildrenDocs(key: string, companyId: string) {
    return this.db.collection("Forms").where("parentKey", "==", key).where("companyId", "==", companyId).orderBy("order");
  }

  public fixForm(form: Form) {
    form.elements = form.elements || [];
    form.links = form.links || [];
    form.linkIds = form.linkIds || [];
    form.children = form.children || [];
    form.actionButtonFullWidth = typeof form.actionButtonFullWidth == "undefined" ? true : !!form.actionButtonFullWidth;
    form.isTerminalNode = !!form.isTerminalNode;
    return form;
  }

  public async setFormOrder(form: Form, order: number) {
    return await this.db.doc("/Forms/" + form.id).set({ order }, { merge: true });
  }

  public async getLinkableElements(formId: string, companyId: string) {
    let linkableElements: LinkableElement[] = [];
    const linkParentFormsQuery = await this.db
      .collection("Forms")
      .where("companyId", "==", companyId)
      .where("linkIds", "array-contains", formId)
      .get();
    for (const doc of linkParentFormsQuery.docs) {
      const form: Form = doc.data() as Form;
      linkableElements.push({ key: form.id, value: form.name, isGroup: true });
      for (const element of form.elements) {
        linkableElements.push({
          key: `${form.id}/${element.id}`,
          value: element.title,
          title: `${form.name}.${element.title}`,
          icon: element.icon,
          isGroup: false,
        });
      }
      await this.loadParentElements(form.id, linkableElements);
      if (form.linkIds.length > 0) linkableElements = [...linkableElements, ...(await this.getLinkableElements(form.id, companyId))];
    }

    await this.loadParentElements(formId, linkableElements);

    return linkableElements;
  }

  private async loadParentElements(formId: string, linkableElements: LinkableElement[]): Promise<void> {
    const form = (await this.getById(formId)).data() as Form;
    const ids = form.key.split("/");
    const parentIds = ids.filter((x, index) => index < ids.length - 1);
    for (let i = parentIds.length - 1; i >= 0; i--) {
      // const currentParentIds = parentIds.filter((x, index) => index <= i);
      // const key = currentParentIds.join("/");
      const doc = await this.getById(parentIds[i]);
      const docData = doc.data() as Form;
      if (docData) {
        linkableElements.push({ key: docData.id, value: docData.name, isGroup: true });
        for (const element of docData.elements) {
          linkableElements.push({
            key: `${docData.id}/${element.id}`,
            value: element.title,
            title: `${docData.name}.${element.title}`,
            icon: element.icon,
            isGroup: false,
          });
        }
      }
    }
  }

  public async getLinkAlias(companyId: string, linkAlias: string) {
    return await this.db.collection("LinkAliases").doc(`${companyId}.${linkAlias}`).get();
  }

  public async setLinkAliasExtraData(companyId: string, linkAlias: string, formId: string) {
    return await this.db.collection("LinkAliases").doc(`${companyId}.${linkAlias}`).set(
      {
        formId,
      },
      { merge: true }
    );
  }

  public async deleteLinkAliases(formId: string) {
    (await this.db.collection("LinkAliases").where("formId", "==", formId).get()).forEach((x) => x.ref.delete());
  }

  public async setFormLinkIds(formId: string, linkIds: string[]) {
    return await this.db.doc(`/Forms/${formId}`).update("linkIds", linkIds);
  }

  public async deleteUsedElements(formId: string) {
    await this.db.collection("UsedElements").doc(formId).delete();
  }

  public async addUsedElements(formId: string, elementFormId: string, elementId: string) {
    await this.db
      .collection("UsedElements")
      .doc(formId)
      .set(
        {
          elementIds: firebase.firestore.FieldValue.arrayUnion(elementId),
          formIds: firebase.firestore.FieldValue.arrayUnion(elementFormId),
        },
        { merge: true }
      );
  }

  public async getLayoutSettingsFromAncestors(visitKey: string): Promise<LayoutSetting | null> {
    const s = visitKey.split("/");
    const parentVisitKey = s.filter((x, i) => i < s.length - 1).join("/");
    const linkIds = parentVisitKey.split("/link:").filter((x) => !!x);
    for (let a = linkIds.length - 1; a >= 0; a--) {
      const key = linkIds[a];
      const formIds = key.split("/");
      for (let i = formIds.length - 1; i >= 0; i--) {
        // const currentKey = formIds.slice(0, i + 1).join("/");
        const currentId = formIds[i];
        const form = (await this.getById(currentId)).data() as Form;
        if (form.layoutSettings && form.layoutSettings.enabled) {
          return form.layoutSettings;
        }
      }
    }
    return null;
  }

  public async getThemeFromAncestors(visitKey: string): Promise<string | null> {
    const s = visitKey.split("/");
    const parentVisitKey = s.filter((x, i) => i < s.length - 1).join("/");
    const linkIds = parentVisitKey.split("/link:").filter((x) => !!x);
    for (let a = linkIds.length - 1; a >= 0; a--) {
      const key = linkIds[a];
      const formIds = key.split("/");
      for (let i = formIds.length - 1; i >= 0; i--) {
        // const currentKey = formIds.slice(0, i + 1).join("/");
        const currentId = formIds[i];
        const form = (await this.getById(currentId)).data() as Form;
        if (form.selectedTheme) {
          return form.selectedTheme;
        }
      }
    }
    return null;
  }

  public async getElementUsages(companyId: string, elementId: string) {
    return await this.db
      .collection("UsedElements")
      .where("companyId", "==", companyId)
      .where("elementIds", "array-contains", elementId)
      .get();
  }
}


import Vue from "vue";
import formService from "@/services/formService";
import folderService from "@/services/folderService";
import IdGenerator from "../../../../shared/extensions/IdGenerator";
import { Form, Folder } from "../../../../shared/models";
import sleep from "../../../../shared/extensions/sleep";
import Dialog from "../Dialog/Dialog.vue";

export default Vue.extend({
  name: "FormsTreeView",
  components: { Dialog },
  props: {
    forms: {
      type: Array as () => Form[],
      default: function () {
        return [];
      },
    },
    selectedItem: {
      type: Object as () => Form | null,
      default: null,
    },
    menu: {
      type: Boolean,
      default: false,
    },
    showAllFormsButton: {
      type: Boolean,
      default: false,
    },
    treeKey: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      deleting: false,
      adding: false,
      openForms: [] as string[],
      treeKeyValue: this.treeKey,
      dragItem: null as Form | null,
      showEditFolderId: null as string | null,
      updatedFolderName: "",
    };
  },
  methods: {
    async AddNewRootInFolder(item) {
      await this.addRoot(item.id);
    },
    async saveEditFolderName(item) {
      item.name = this.updatedFolderName;
      await folderService.updateFolderName(item.id, item.name);
      this.updatedFolderName = "";
      this.showEditFolderId = null;
    },
    closeEditFolderName() {
      this.updatedFolderName = "";
      this.showEditFolderId = null;
    },
    async showFolderName(item) {
      this.updatedFolderName = item.name;
      this.showEditFolderId = item.id;
    },
    async addFolder() {
      try {
        this.adding = true;
        const id = IdGenerator.newId();
        const newFolder: Folder = {
          id,
          companyId: this.companyId,
          name: "New Folder",
        };
        await folderService.saveFolder(newFolder);
        await new Promise((resolve, reject) => {
          this.$emit(
            "change",
            async () => {
              try {
                await this.$nextTick();
              } catch (error) {
                reject(error);
                return;
              }
              resolve(0);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.adding = false;
      }
    },
    allowDrop(e, item: Form | null) {
      if ((this.dragItem as any).isFolder) return;

      if (item && (item as any).isFolder) {
        (e.target as Element).classList.add("allow-drop");
        e.preventDefault();
      }

      if (item && this.dragItem) {
        if (item.key && item.key.startsWith(this.dragItem.key)) {
          return;
        }
      }
      if (this.dragItem && this.dragItem.isLink) {
        return;
      }
      if (!item || this.dragItem?.id != item?.id) {
        (e.target as Element).classList.add("allow-drop");
        e.preventDefault();
      }
    },
    dragleave(e) {
      (e.target as Element).classList.remove("allow-drop");
    },
    dragStart(e, item: Form) {
      this.dragItem = item;
      e.dataTransfer.setData("item", JSON.stringify(item));
      const dragElm = document.createElement("div");
      dragElm.id = `drag-item-${item.id}`;
      dragElm.innerText = item.name;
      document.body.appendChild(dragElm);
      e.dataTransfer.setDragImage(dragElm, 0, 0);
      document.getElementsByClassName("card-drop-place").item(0)?.classList.remove("hide");
    },
    dragEnd(e, item: Form) {
      this.dragItem = null;
      const dragElm = document.getElementById(`drag-item-${item.id}`);
      if (dragElm) {
        document.body.removeChild(dragElm);
      }
      document.getElementsByClassName("card-drop-place").item(0)?.classList.add("hide");
    },
    dropOnItem(e, item: Form) {
      e.preventDefault();
      var draggedItem = JSON.parse(e.dataTransfer.getData("item"));
      if (!this.formHasVariable(draggedItem)) {
        if ((item as any).isFolder) {
          this.moveFormToFolder(draggedItem.id, item.id);
        } else {
          this.moveForm(draggedItem, item.key);
        }
      } else {
        (this.$refs.confirmDialog as any)
          .show({
            title: `Move ${draggedItem.name}`,
            message: "This move will result in broken variables that you will need to fix, continue ?",
            okButton: "Confrim",
            cancelButton: "Cancel",
          })
          .then((response) => {
            if (response) {
              if ((item as any).isFolder) {
                this.moveFormToFolder(draggedItem.id, item.id);
              } else {
                this.moveForm(draggedItem, item.key);
              }
            }
          });
      }
    },
    dropOnRoot(ev) {
      ev.preventDefault();
      var draggedItem = JSON.parse(ev.dataTransfer.getData("item"));
      if (!this.formHasVariable(draggedItem)) {
        if (draggedItem.folderId && draggedItem.folderId.length > 0) {
          draggedItem.folderId = "";
          this.moveFromFolderToRoot(draggedItem);
        } else {
          this.moveForm(draggedItem, "");
        }
      } else {
        (this.$refs.confirmDialog as any)
          .show({
            title: `Move ${draggedItem.name}`,
            message: "This move will result in broken variables that you will need to fix, continue ?",
            okButton: "Confrim",
            cancelButton: "Cancel",
          })
          .then((response) => {
            if (response) {
              if (draggedItem.folderId && draggedItem.folderId.length > 0) {
                draggedItem.folderId = "";
                this.moveFromFolderToRoot(draggedItem);
              } else {
                this.moveForm(draggedItem, "");
              }
            }
          });
      }
    },
    formHasVariable(form: Form) {
      let hasVariable = form.question && form.question.includes('data-type="mention"');
      if (!hasVariable && form.children) {
        for (let child of form.children) {
          hasVariable = this.formHasVariable(child);
          if (hasVariable == true) return hasVariable;
        }
      }
      return hasVariable;
    },
    moveFromFolderToRoot(form: Form) {
      this.$emit("moveFromFolderToRoot", form, async () => {
        return new Promise((resolve, reject) => {
          try {
            this.$emit("change", async () => {
              this.treeKeyValue++;
              await this.$nextTick();
              await this.itemClicked(form);
              await this.openForm(form.id);
              resolve(null);
            });
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    moveFormToFolder(formId: string, folderId: string) {
      this.$emit("moveFormToFolder", formId, folderId, async () => {
        return new Promise((resolve, reject) => {
          try {
            this.$emit("change", async () => {
              this.treeKeyValue++;
              await this.$nextTick();
              resolve(null);
            });
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    moveForm(form: Form, newFormKey) {
      this.$emit("moveForm", form.id, newFormKey, async () => {
        return new Promise((resolve, reject) => {
          try {
            this.$emit("change", async () => {
              this.treeKeyValue++;
              await this.$nextTick();
              await this.itemClicked(form);
              await this.openForm(newFormKey ? newFormKey : `${form.id}`);
              resolve(null);
            });
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    async showSessionsAndChildren(item: Form) {
      if (!(item as any).isFolder) {
        this.$emit("showSessionsAndChildren", item);
        this.openForm(item.key);
      }
    },
    async openForm(key: string) {
      const result: string[] = [];
      let current = "";
      const arr = key.split("/");
      for (const item of arr) {
        current += item + "/";
        result.push(current.substring(0, current.length - 1));
      }
      this.openForms = [...this.openForms, ...result];
    },
    async itemClicked(item) {
      if (!item.isFolder) {
        if (this.menu) {
          this.$emit("select", item);
          return;
        }
        if (item.isLink) {
          // this.openForms = [];
          this.$emit("showLink", item);
          window.setTimeout(() => {
            const selectedListItem = document.querySelector(".list-item.selected");
            if (selectedListItem) {
              selectedListItem.scrollIntoView({ behavior: "smooth" });
            }
            window.setTimeout(() => {
              const cardTree = document.querySelector(".card-tree");
              if (cardTree) {
                cardTree.scrollTop -= 20;
              }
            }, 500);
          }, 600);
        } else {
          this.$emit("select", item);
        }
      }
    },
    async addRoot(folderId: string | null = null) {
      try {
        this.adding = true;
        const id = IdGenerator.newId();
        let formName = `New Form ${this.forms.length + 1}`;
        if (folderId) {
          const folder = this.forms[this.forms.findIndex((item) => item.id == folderId)];
          const childLength = (folder as any).children?.length ?? 0;
          formName = `New Form ${childLength + 1}`;
        }
        const newForm: Form = {
          id,
          key: id,
          name: formName,
          question: "",
          optionLabel: "",
          parent: true,
          media: null,
          youtube: null,
          companyId: this.companyId,
          archived: false,
          submission: {
            emailNotification: false,
            enabled: false,
          },
          elements: [],
          links: [],
          linkIds: [],
          folderId: folderId ?? "",
          selectedTheme: null,
          visits: [],
          layoutSettings: null,
          order: this.forms.length,
          parentKey: "",
        };
        await formService.saveForm(newForm);
        await new Promise((resolve, reject) => {
          this.$emit(
            "change",
            async () => {
              try {
                await this.$nextTick();
                let form: Form | null = null;
                while (!form) {
                  form = this.forms.find((x) => x.id == newForm.id) || null;
                  if (folderId) {
                    const folder = this.forms[this.forms.findIndex((item) => item.id == folderId)];
                    form = (folder as any).children?.find((x) => x.id == newForm.id);
                    const result: string[] = [];
                    let current = "";
                    const arr = (folder as any).visitKey.split("/");
                    for (const item of arr) {
                      current += item + "/";
                      result.push(current.substring(0, current.length - 1));
                    }
                    this.openForms = [...this.openForms, ...result];
                  }
                  if (form) {
                    await this.itemClicked(form);
                  }
                  await sleep(500);
                }
              } catch (error) {
                reject(error);
                return;
              }
              resolve(0);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.adding = false;
      }
    },
    appendStyle() {
      let mainNodes = document.querySelectorAll(".v-treeview-node");
      mainNodes.forEach((item) => {
        const root = item.querySelector(".v-treeview-node__root");
        if (root) {
          const folderNode = root.querySelector(".isFolder");
          if (folderNode) item.classList.add("bordered");
        }
      });
    },
  },
  watch: {
    forms(): void {
      if (this.selectedItem) {
        this.openForm(this.selectedItem.key);
      }
      setTimeout(() => {
        this.appendStyle();
      }, 100);
    },
    treeKey(v) {
      this.treeKeyValue = v;
      setTimeout(() => {
        this.appendStyle();
      }, 100);
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
    userId(): string {
      return this.$store.getters.userId;
    },
  },
});


import sessionService from "@/services/sessionService";
import SessionDataValues from "@/components/audience/SessionDataValues.vue";
import store from "@/store";
import Vue from "vue";
import { ConcurrentSessionDetails, SessionVisit } from "../../../../shared/models";
import firebase from "../../../../shared/node_modules/firebase/compat";

export default Vue.extend({
  name: "SessionDataValues",
  components: {
    SessionDataValues,
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    formIds: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    isRoot: {
      type: Boolean,
      required: false,
      default: false,
    },
    concurrentSessionDetails: {
      type: Object as () => ConcurrentSessionDetails,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      sessionvisits: [] as SessionVisit[],
    };
  },
  mounted() {
    this.loadSessionVisits();
  },
  methods: {
    async loadSessionVisits() {
      try {
        this.loading = true;
        const lastSeenDateTime = firebase.firestore.Timestamp.now();
        this.$emit("seen", lastSeenDateTime);
        const col = await sessionService.getSessionVisitChildren(this.companyId, this.sessionId, this.formIds);
        this.sessionvisits = col.docs.map((x) => ({
          id: x.id,
          ...x.data(),
        }));
        if (this.isRoot) {
          await sessionService.SetSeenSession(lastSeenDateTime, this.sessionId, store.state.userDetail.userData.id ?? "");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
  },
});

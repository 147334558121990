import axios from "@/plugins/axios";
import { Folder } from "../../../shared/models";

class FolderService {
  public async saveFolder(folder: Folder) {
    const response = await axios.post(`/Folder`, folder);
    return response.data;
  }

  public async updateFolderName(id: string, name: string) {
    const response = await axios.put(`/Folder`, { id: id, name: name });
    return response.data;
  }
}

const folderService = new FolderService();
export default folderService;

import BaseSessionService from "../../../shared/services/SessionService";
import { db } from "../../../shared/plugins/firebase-app";
import axios from "@/plugins/axios";
import firebase from "../../../shared/node_modules/firebase/compat";
import { FindSessionsCriteriaRequestModel } from "../../../shared/models/requests/FindSessionsCriteriaRequestModel";

class SessionService extends BaseSessionService {
  constructor() {
    super(db);
  }

  async FindSessions(criteria: FindSessionsCriteriaRequestModel) {
    const response = await axios.post("/Session", criteria);
    const result = response.data;
    result.forEach((item) => {
      item.startDatetime = new firebase.firestore.Timestamp(item.startDatetime._seconds, item.startDatetime._nanoseconds);
      item.lastModifiedDateTime = new firebase.firestore.Timestamp(
        item.lastModifiedDateTime._seconds,
        item.lastModifiedDateTime._nanoseconds
      );
      item.lastSeenDateTime = item.lastSeenDateTime
        ? new firebase.firestore.Timestamp(item.lastSeenDateTime._seconds, item.lastSeenDateTime._nanoseconds)
        : null;

      if (item.concurrentSessionDetails) {
        item.concurrentSessionDetails.forEach((cItem) => {
          cItem.sessionLastUpdate = new firebase.firestore.Timestamp(
            cItem.sessionLastUpdate._seconds,
            cItem.sessionLastUpdate._nanoseconds
          );
          cItem.sessionStartTime = new firebase.firestore.Timestamp(cItem.sessionStartTime._seconds, cItem.sessionStartTime._nanoseconds);
        });
      }
    });
    return result;
  }

  async SetSeenSession(lastSeenDateTime: firebase.firestore.Timestamp, sessionId: string, userId: string) {
    const response = await axios.patch("/Session", { lastSeenDateTime, sessionId, userId, sessionSeen: true });
    return response.data;
  }

  public async exportSessions(companyId: string, sessionIds: string[], type: string) {
    if (sessionIds && sessionIds.length > 0) {
      const response = await axios.post("/ExportSession", { companyId: companyId, sessionIds: sessionIds, type: type });
      return response.data;
    }
  }
}

const sessionService = new SessionService();
export default sessionService;


import Vue from "vue";
import formService from "@/services/formService";
import HeatMap2 from "@/components/audience/HeatMap2.vue";
import SessionData from "@/components/audience/SessionData.vue";
import { Contact, ContactGroup, Form } from "../../../shared/models";
import store from "@/store";
import contactService from "@/services/contactService";
import contactGroupService from "@/services/contactGroupService";
import FormsTreeView from "@/components/form/FormsTreeView.vue";
import DateRangePicker from "@/components/field-box/DateRangePicker.vue";

export default Vue.extend({
  components: {
    HeatMap2,
    SessionData,
    FormsTreeView,
    DateRangePicker,
  },
  data() {
    return {
      currentTab: 0,
      forms: [] as Form[],
      selectedForm: null as Form | null,
      loading: false,
      loadingForms: false,
      refreshKey: 0,
      contacts: [] as Contact[],
      contactGroups: [] as ContactGroup[],
      dateRange: [] as string[],
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.loadAllForms();
      await this.loadContacts();
      await this.loadContactGroups();
    } catch (error: any) {
      console.error(error);
      this.$notification.showError(error.message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async loadAllForms(successCallBack?, failCallBack?) {
      try {
        const docs = await formService.rootFormsList(this.companyId);
        this.forms = [];
        for (const doc of docs.docs) {
          var form = doc.data() as Form;
          if (form.companyId && form.companyId == this.companyId) {
            form.id = doc.id;
            form.key = doc.id;
            form.children = [];
            this.forms.push(form);
          }
        }
        if (successCallBack) {
          successCallBack();
        }
      } catch (error: any) {
        console.error(error);
        if (failCallBack) {
          failCallBack(error);
        }
      }
    },
    async loadContacts(callBack?) {
      if (this.userId !== "") {
        this.contacts = [];
        const docs = await contactService.getContacts(this.companyId);
        if (docs) {
          docs.forEach((doc) => {
            var u = doc.data();
            u.id = doc.id;
            this.contacts.push(u);
          });
        }
      }
    },
    async loadContactGroups(callBack?) {
      if (this.userId !== "") {
        this.contactGroups = [];
        const docs = await contactGroupService.getContactGroups(this.companyId);
        if (docs) {
          docs.forEach((doc) => {
            var u = doc.data();
            u.id = doc.id;
            this.contactGroups.push(u);
          });
        }
      }
    },
    selectForm(form: Form | null) {
      if (form && this.forms.findIndex((x) => x.key === form.key) < 0) {
        this.forms.push({ ...form, hidden: true } as any);
      }
      this.selectedForm = form;
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    userId(): string {
      return store.getters.userId;
    },
    visibleForms(): Form[] {
      return this.forms.filter((x) => !(x as any).hidden);
    },
  },
});

import axios from "axios";
import { cloudFunctionsBaseURL } from "../../../shared/constants";

const customInstance = axios.create({
  baseURL: cloudFunctionsBaseURL,
});

customInstance.defaults.withCredentials = false;
axios.defaults.withCredentials = false;
export const axiosStatic = axios;
export default customInstance;


import sessionService from "@/services/sessionService";
import SessionDataValues from "@/components/audience/SessionDataValues.vue";
import Vue from "vue";
import { Contact, ContactGroup, Form, Ip, Session } from "../../../../shared/models";
import { FindSessionsCriteriaRequestModel } from "../../../../shared/models/requests/FindSessionsCriteriaRequestModel";

import IpsService from "@/services/IpsService";
import store from "@/store";

export default Vue.extend({
  components: {
    SessionDataValues,
  },
  props: {
    form: {
      type: Object as () => Form,
      required: false,
    },
    contacts: {
      type: Array as () => Contact[],
      required: false,
    },
    contactGroups: {
      type: Array as () => ContactGroup[],
      required: false,
    },
    dateRange: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    seenFilter: {
      type: String,
      required: false,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      sessions: [] as Session[],
      headers: [
        {
          text: "Session ID",
          align: "left",
          sortable: true,
          value: "id",
        },
        { text: "Start Date", value: "startDatetime" },
        { text: "Last Updated", value: "lastModifiedDateTime" },
        { text: "Clicker Email", value: "clickerEmailAddress" },
        { text: "Contact Emails", value: "contactIds" },
        { text: "Concurrent Sessions", value: "concurrentSessionIds", align: "center", width: "100px", class: "center-header" },
        { text: "Ip Address", value: "userIp", width: "100px", align: "center" },
        { text: "User Agent", value: "userAgent" },
        { text: "Data", value: "data-table-expand" },
        { text: "Actions", value: "actions", hide: !this.showActions },
        { text: "", value: "data-table-select" },
      ],
      expanded: [] as Session[],
      infoMessage: "",
      expandCuncurrent: false,
      selectedSession: [],
      htmlLoading: false,
      jsonLoading: false,
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    loading(value) {
      this.$emit("loading", value);
    },
  },
  methods: {
    async getIpGeo(ip) {
      if (ip) {
        const response = await IpsService.getIpDetail(ip);
        if (response) {
          return `${(response as Ip).geo.country_name} - ${(response as Ip).geo.city} (${ip})`;
        } else {
          return ip;
        }
      }
    },
    async exportHtml() {
      this.htmlLoading = true;
      const response = await sessionService.exportSessions(
        this.companyId,
        this.selectedSession.map((item) => (item as Session).id),
        "HTML"
      );
      this.htmlLoading = false;
      this.downloadFile(response, "ExportHtml.html", response.type);
    },
    async exportJson() {
      this.jsonLoading = true;
      const response = await sessionService.exportSessions(
        this.companyId,
        this.selectedSession.map((item) => (item as Session).id),
        "JSON"
      );
      this.jsonLoading = false;
      this.downloadFile(response, "ExportJson.json", response.type, true);
    },
    downloadFile(response: any, name: string, type: string, isJson = false) {
      let blob;
      if (isJson) {
        const jsonData = JSON.stringify(response, null, "\t");
        blob = new Blob([jsonData], { type: type });
      } else {
        blob = new Blob([response], { type: type });
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    expandData(item: any) {
      this.expandCuncurrent = false;
      this.expanded = [];
      this.expanded.push(item);
    },
    unExpandData() {
      this.expandCuncurrent = false;
      this.expanded = [];
    },
    expandConcurrentSessionIds(item: any) {
      this.expanded = [];
      this.expandCuncurrent = true;
      this.expanded.push(item);
    },
    unExpandConcurrentSessionIds() {
      this.expandCuncurrent = false;
      this.expanded = [];
    },
    notSeen(item: Session) {
      return (
        !item.lastSeenDateTime ||
        item.lastSeenDateTime.seconds < item.lastModifiedDateTime.seconds ||
        (item.seenUsers && !item.seenUsers.includes(store.state.userDetail.userData.id))
      );
    },
    setSeen(item, lastSeenDateTime) {
      const session = this.sessions.find((x) => x.id == item.id);
      if (session) {
        session.lastSeenDateTime = lastSeenDateTime;
        if (!session.seenUsers) session.seenUsers = [];
        session.seenUsers = [...session.seenUsers, store.state.userDetail.userData.id ?? ""];
      }
      this.$emit("sessionSeenChanged");
    },
    async loadData() {
      try {
        this.loading = true;
        if (!this.form && !this.dateRange[0]) {
          this.sessions = [];
          this.infoMessage = "A date range or a form should be selected at least.";
          return;
        } else {
          this.infoMessage = "";
        }
        const findSessionCriteria: FindSessionsCriteriaRequestModel = {
          companyId: this.companyId,
          formId: this.form ? this.form.id : null,
          dateRange: this.dateRange,
          seenFilter: this.seenFilter,
          userId: store.state.userDetail.userData.id,
        };
        this.sessions = await sessionService.FindSessions(findSessionCriteria);
        for (let item of this.sessions) {
          item.userIp = await this.getIpGeo(item.userIp);
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getContact(id: string) {
      return this.contacts.find((x) => x.id === id);
    },
    getContactGroup(id: string) {
      return this.contactGroups.find((x) => x.id === id);
    },
    customSort(items: Session[], index: string[], isDesc: boolean[]) {
      const newItems = items.sort((a, b) => {
        if (index[0] === "startDatetime") {
          if (!isDesc[0]) {
            return this.compare(a.startDatetime.seconds, b.startDatetime.seconds);
          } else {
            return this.compare(b.startDatetime.seconds, a.startDatetime.seconds);
          }
        } else if (index[0] === "lastModifiedDateTime") {
          if (!isDesc[0]) {
            return this.compare(a.lastModifiedDateTime?.seconds || 0, b.lastModifiedDateTime?.seconds || 0);
          } else {
            return this.compare(b.lastModifiedDateTime?.seconds || 0, a.lastModifiedDateTime?.seconds || 0);
          }
        } else {
          if (!isDesc[0]) {
            return this.compare(a[index[0]] || 0, b[index[0]] || 0);
          } else {
            return this.compare(b[index[0]] || 0, a[index[0]] || 0);
          }
        }
      });
      return newItems;
    },
    compare(a, b) {
      return a > b ? 1 : a < b ? -1 : 0;
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
    computedHeaders(): any {
      return this.headers.filter((x) => x.hide !== true);
    },
  },
});
